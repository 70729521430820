import { Helmet } from 'react-helmet';

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Scovoicu Bacau</title>
        <meta name="description" content="Scoala Gimnaziala Octavian Voicu Bacau" />
        {/* Alte metadate */}
      </Helmet>

      

<div className="bg-white rounded-lg w-auto animate-fade-in delay-200">
  <div className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
    <div>
      <p className="text-lg font-semibold">Anunt concurs - Administrator financiar</p>
    </div>
    <a
      href="/documente/administrator-financiar-2025-02-20-anunt"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii
    </a>
       <a
      href="/documente/cerere-inscriere-concurs-administrator-financiar"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Cerere
    </a>
  </div>
</div>
<br/>
<div className="bg-white rounded-lg w-auto animate-fade-in delay-200">
  <div className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
    <div>
      <p className="text-lg font-semibold">Olimpiada Judeteana de Fizica, Bacau, 9 martie 2025 - Rezultate initiale</p>
    </div>
    <a
      href="/documente/onf-bacau-2025-rezultate-initiale"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii
    </a>
  </div>
</div>
<br/>


 <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">Înscrierea în învățământul primar - clasa pregătitoare (2025-2026)</span> la
          <br />
          <span class="text-blue-600 font-bold">Școala Gimnazială Octavian Voicu</span> din Bacău!
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
        <br/>
        Înscrierea în învățământul primar se face în baza metodologiei specifice, aprobată de Ministerul Educației. Astfel, copiii care împlinesc vârsta de 6 ani până la data de 31 august 2024, inclusiv, trebuie să fie înscriși în clasa pregătitoare, în conformitate cu prevederile legii nr. 198/2023, cu modificările și completările ulterioare. La sediile inspectoratelor școlare județene și la avizierul de la Școala Gimnazială „Octavian Voicu” Bacău, respectiv pe site-ul școlii – www.scovoicu.ro, se poate consulta lista cu circumscripțiile școlare. 
Părinții ai căror copii împlinesc în anul curent vârsta de 6 ani în perioada 1 septembrie - 31 decembrie, inclusiv, pot să își înscrie copiii în învățământul primar, în clasa pregătitoare, dacă nivelul lor de dezvoltare este corespunzător.
Toate unitățile de învățământ au obligația de a oferi informații referitoare la procedura de înscriere a copiilor în învățământul primar, indiferent dacă părinții fac parte sau nu din circumscripția respectivă.

Informații suplimentare:
•	la Inspectoratului Școlar Județean Bacău (isjbacau.ro)
•	la avizierul Școlii Gimnaziale „Octavian Voicu” Bacău  (scoala1bacau@yahoo.com, 0234 552 061)
•	pe site-ul școlii www.scovoicu.ro
      <br/>
<a
      href="/documente/ov-anunt-incriere-clasa-pregatitoare-2025"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii (pdf) aici ...
    </a>
    <br/><br/>
  Vă așteptăm cu drag,
<br/>
Ehipa Școlii Gimnaziale ,,Octavian Voicu” Bacău🎉


        </p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">Zilele porților deschise</span> la
          <br />
          <span class="text-blue-600 font-bold">Școala Gimnazială Octavian Voicu</span> din Bacău!
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          Dragi părinți și copii, vă invităm cu drag să descoperiți universul învățării la Zilele Porților Deschise, organizate în perioada 5-7 martie 2025, între orele 9:00-12:00, la Școala Gimnazială „Octavian Voicu” Bacău (Strada Bicaz nr. 17, Municipiul Bacău).
          Veți avea ocazia să:
✔ Vizitați școala și grădinița – un mediu modern și prietenos pentru educație 🏫
✔ Întâlniți  cadrele didactice, personalul nedidactic și cadrele auxiliare 👩🏫👨🏫
✔ Să aflați mai multe despre procesul educațional – metode interactive și moderne de învățare 
✔ Fiți informați despre oferta educațională și programele școlare 🎓
<br/>
✨ Oferta noastră educațională include:
🎉 Grupe de antepreșcolari (creșă) – pentru anul școlar următor
📖 Clase pregătitoare – un început blând și sigur pentru cei mici
📘 Învățământ primar și gimnazial – predare la standarde ridicate, adaptată nevoilor elevilor
🌍 Limbi străine – engleză  și franceză
💡 Proiecte educaționale și extracurriculare – activități creative, culturale și științifice
🎭 Cluburi și ateliere tematice – dezvoltare personală, sportivă și artistică
💻 Acces la tehnologie modernă – laboratoare dotate și resurse digitale pentru o învățare interactivă
<br/>
👦 Acest eveniment este dedicat tuturor părinților și copiilor care doresc să descopere oportunitățile educaționale oferite de școala noastră și să facă primii pași spre un viitor de succes.
<br/><br/>
<a
      href="/documente/pliant-special-martie-2025"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi broșura (pdf) aici ...
    </a>
    <br/><br/>
  Vă așteptăm cu drag,
<br/>
Ehipa Școlii Gimnaziale ,,Octavian Voicu” Bacău🎉


        </p>
      </div>


      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">Bine ați venit</span> la
          <br />
          <span class="text-blue-600 font-bold">Școala Gimnazială Octavian Voicu</span> din Bacău!
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          Situată la periferia orașului Bacău, <span class="font-bold">Școala Gimnazială Octavian Voicu</span> este locul unde educația de calitate și dezvoltarea armonioasă a copiilor se află în prim-plan. Cu o tradiție îndelungată în învățământ, școala noastră oferă un mediu sigur, prietenos și stimulativ pentru elevi, unde aceștia sunt încurajați să își descopere pasiunile, să își dezvolte abilitățile și să atingă potențialul maxim.
          Misiunea noastră este să formăm viitorii cetățeni ai comunității, pregătindu-i atât academic, cât și din punct de vedere al valorilor umane. Profesorii noștri dedicați aplică metode moderne de predare, adaptate nevoilor fiecărui elev, într-o atmosferă plină de încredere și respect reciproc.
          <br />
          Ne mândrim cu rezultatele elevilor noștri, fie că este vorba despre performanțe la examene, concursuri școlare sau implicare în activități extracurriculare. Printr-o abordare centrată pe elev, ne dorim să cultivăm curiozitatea intelectuală, creativitatea și spiritul de colaborare.
          <br />
          Vă invităm să descoperiți mai multe despre noi și să faceți parte din comunitatea noastră școlară!
        </p>
      </div>

      <div className="mt-4 md:flex items-center">

        <div className="bg-indigo-400 rounded-lg m-10 w-auto md:w-1/2">
          <p className="mt-8 ml-8 mr-8 text-white text-base text-3xl font-bold text-center">
            Deviza
          </p>
          <p className="mb-8 ml-8 mr-8 mb-8 text-white text-base md:text-sm text-center text-2xl">Numai împreună învățând reușim să ne pregătim pentru o societate optimă! Copiii de azi clădesc statul de mâine, ei învață și aleg conștient!</p>
        </div>
        <div className="bg-indigo-400 rounded-lg m-10 w-auto md:w-1/2">
          <p className="mt-8 ml-8 mr-8 text-white text-base text-3xl font-bold text-center">
            Misiunea
          </p>
          <p className="mb-8 ml-8 mr-8 mb-8 text-white text-base md:text-sm text-center text-2xl">Se oferă educație la standard de calitate prin centrarea învățării pe elev, pentru desăvârșirea intelectuală, morală și profesională a elevilor, în vederea adaptării la schimbarea continuă a societății, condiție esențială a progresului economic și cultural.</p>
        </div>
      </div>

      <div className="bg-white rounded-lg m-11 w-auto">
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          Ținte strategice
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">1. Formarea cadrelor didactice în vederea asigurării creșterii calității în educație pentru toate domeniile și toți indicatorii din standardele de evaloare periodică a unităților de învățământ preuniversitar</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">2. Promovarea imaginii școlii în contextul climatului concurențial actual de descentralizare și autonomie instituțională</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">3. Reconsiderarea managementului la nivelul școlii și al clasei în vederea egalizării șanselor la educație pentru toți elevii în vederea eradicării pericolului de abandon școlar</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">4. Păstrarea și modernizarea bazei tehnico-materiale și generalizarea accesului la informația electronică</p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
      <div class="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
  <div>
        <p class="text-lg">
	  Tichete sociale pentru achiziția de produse alimentare,<br/>
	  prin Programul 'Sprijin pentru România'
	</p>
  </div>
  <a 
    href="https://mfe.gov.ro/programe/autoritati-de-management/autoritatea-de-management-pentru-programul-operational-ajutorarea-persoanelor-defavorizate/tichete-sociale-pentru-achizitia-de-produse-alimentare-prin-programul-sprijin-pentru-romania/"
    class="bg-white text-blue-500 font-semibold py-1 px-2 text-sm 
    rounded hover:bg-gray-200 transition"
  >
    Vezi detalii
  </a>
</div>
</div>

      <br /><br /><br /><br />
    </>
  );
}
